import React from 'react';

// import services data
import { services } from '../data';

const Services = () => {
  return (
    <section id='services' className='section bg-tertiary'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='section-title before:content-services relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-28 before:hidden before:lg:block'>
            ¿Qué hacemos por ti?
          </h2>
          <p className='subtitle'>
          Trabajando desde hace más de dos años sabemos las necesidades de todo emprendedor Huaracino, 
          por eso te ofrecemos una flota de motorizados capacitados y con experiencia en el sector de reparto de alimentos
           y paquetería en general, con toda la logística que hay detras, 
           prueba nuestro servicio <span className='font-bold text-white uppercase'>gratis</span> en tus dos primeras entregas, así puedas confirmar la calidad de nuestro servicio. (Corporativo)
          </p>
        </div>
        <div className='grid lg:grid-cols-4 gap-8'>
          {services.map((service, index) => {
            const { icon, name, description } = service;
            return (
              <div className='bg-secondary p-6 rounded-2xl' key={index}>
                <img src={icon} className=' text-white rounded-sm w-12 h-12 flex justify-center items-center mb-24 text-[28px]'/>
                  
                
                <h4 className='text-xl font-medium mb-2'>{name}</h4>
                <p>{description}</p>
                
                
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
