import React, { useEffect, useState } from 'react';

// import components
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'


const Testimonials = () => {

  return (
    <section id='testimonials' className='section bg-secondary'>
      <div className='md:m-32 items-center'>
      <LiteYouTubeEmbed
        id="fBdcji1KJB4"
        title="Waras"
        poster="maxresdefault"
/>
      </div>
    </section>
  );
};

export default Testimonials;
