import React from 'react';

// import img
import Image from '../assets/img/banner-waras.jpg';


const About = () => {
  return (
    <section className='section bg-secondary' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <img
            className='object-cover h-full w-[800px] md:mx-auto lg:mx-0 rounded-2xl'
            src={Image}
            alt=''
          />
          <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
            <div className='flex flex-col'>
              <h2 className='text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                Waras Delivery
              </h2>
              <p className='mb-4 text-accent'>
                Tu pedido a la puerta de tu casa!
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8'>
                Somos una empresa de transporte de pedidos, trabajamos con restaurantes, pollerías, cevicherías, y tiendas de todo Huaraz.
                 Además recogemos tus pedidos y paquetes del lugar que requieras y lo llevamos a donde desees, la entrega es rápida y confiable, brindandote un servicio exclusivo y de calidad.<br />
                <br />
                Transportamos tu pedido, garantizando el cumplimiento de los protocolos de bioseguridad, brindando nuestra politica de <span className='font-bold text-white uppercase'>contacto cero</span>
              </p>
            </div>
            <a href='https://wa.link/9g9kqa'>
            <button className='btn btn-md bg-accent hover:bg-secondary-hover transition-all'>
              Quiero más información
            </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
