//  icons



// companies icons
import kaypacha from './assets/img/brands/brand-kaypacha.png'
import manka from './assets/img/brands/brandManka.png';
import jimHua from './assets/img/brands/JimHua.png';
import ancestral from './assets/img/brands/ancestral.png';
import casaBlanca from './assets/img/brands/casaBlanca.png';
import nautico from './assets/img/brands/nautico.png';
import jamay from './assets/img/brands/jamay.png';
import rincMinero from './assets/img/brands/rincMinero.png';
import viejoBruno from './assets/img/brands/viejoBruno.png';
import emiliaFerre from './assets/img/brands/emiliaFerre.png';
import kanka from './assets/img/brands/kanka.png';
import laColina from './assets/img/brands/laColina.png';
import parcero from './assets/img/brands/parcero.png';
import pashta from './assets/img/brands/pashta.png';
import bb from './assets/img/brands/bb.png';
import pizzaAbuelo from './assets/img/brands/pizzaAbuelo.png';
import zarumilla from './assets/img/brands/zarumilla.png';
import rincParrillero from './assets/img/brands/rincParrillero.png';
import mamaMia from './assets/img/brands/mamaMia.png';
import sweetPetit from './assets/img/brands/sweetPetit.png';
import tambo from './assets/img/brands/tambo.png';
import tamya from './assets/img/brands/tamya.png';
import pizzaBruno from './assets/img/brands/pizzaBruno.png';
import taita from './assets/img/brands/taita.png';
import tramonti from './assets/img/brands/tramonti.png';
import wafflesHuaraz from './assets/img/brands/wafflesHuaraz.png';
import pechugon from './assets/img/brands/pechugon.png';
import saboresCasa from './assets/img/brands/saboresCasa.png';
import kebabAndino from './assets/img/brands/kebabAndino.png';
import trivio from './assets/img/brands/trivio.png';
import inkaFrut from './assets/img/brands/inkaFrut.png';
import rossonero from './assets/img/brands/rossonero.png';
import hnc from './assets/img/brands/hnc.png';
import castroI from './assets/img/brands/castroI.png';
import vincenzo from './assets/img/brands/vincenzo.png';
import chifaBlas from './assets/img/brands/chifaBlas.png';
import hierbaBuena from './assets/img/brands/hierbaBuena.png';
import crillon from './assets/img/brands/crillon.png'
import rubrix from './assets/img/brands/rubrix.png'
import tresCarbones from './assets/img/brands/tresCarbones.png'
import jhonHouse from './assets/img/brands/jhonHouse.png'
import bakari from './assets/img/brands/bakari.png'
import liStore from './assets/img/brands/liStore.png'
import laEsquina from './assets/img/brands/laEsquina.png'
import kl from './assets/img/brands/kl.png'
import kafer from './assets/img/brands/kafer.png'
import sanFrancisco from './assets/img/brands/sanFrancisco.png'
import antojo from './assets/img/brands/antojo.png'
import barraDeMordiscos from './assets/img/brands/barraDeMordiscos.png'
import mordiscos from './assets/img/brands/mordiscos.png'
import lasVegas from './assets/img/brands/lasVegas.png'

// projects images
import Project1 from './assets/img/projects/p1.webp';
import Project2 from './assets/img/projects/p2.webp';
import Project3 from './assets/img/projects/p3.webp';
import Project4 from './assets/img/projects/p4.webp';
import Project5 from './assets/img/projects/p5.webp';
import Project6 from './assets/img/projects/p6.webp';

// skills images
import SkillImg1 from './assets/img/skills/html5.png';
import SkillImg2 from './assets/img/skills/css3.png';
import SkillImg3 from './assets/img/skills/js.png';
import SkillImg4 from './assets/img/skills/reactjs.png';
import SkillImg5 from './assets/img/skills/nextjs.png';
import SkillImg6 from './assets/img/skills/nodejs.png';
import SkillImg7 from './assets/img/skills/git.png';
import SkillImg8 from './assets/img/skills/figma.png';

// testimonial images
import TestiImage1 from './assets/img/testimonials/testimonial-1.webp';
import TestiImage2 from './assets/img/testimonials/testimonial-2.webp';
import TestiImage3 from './assets/img/testimonials/testimonial-3.webp';

//logos
import youtubeLogo from './assets/img/logos/youtube.png'
import facebookLogo from './assets/img/logos/facebook.png'
import instagramLogo from './assets/img/logos/instagram.png'
import userSVG from './assets/img/logos/icons/person.svg'
import inventorySVG from './assets/img/logos/icons/inventory.svg'
import usersSVG from './assets/img/logos/icons/group.svg'
import shoppingCartSVG from './assets/img/logos/icons/shopping_cart.svg'
import whatsappLogo from './assets/img/logos/whatsapp.png'
import mailSVG from './assets/img/logos/icons/mail.svg'
import locationSVG from './assets/img/logos/icons/location.svg'

// navigation
export const navigation = [
  {
    name: 'Inicio',
    href: 'home',
  },
  {
    name:'Asociados',
    href: 'brands'
  },
  {
    name: 'Nosotros',
    href: 'about',
  },
  
  {
    name: 'Servicios',
    href: 'services',
  },
  
  {
    name: 'Video',
    href: 'testimonials',
  },
  {
    name: '',
    href: 'contact',
  },
];

// social
export const social = [
  {
    icon: youtubeLogo,
    href: 'https://www.youtube.com/channel/UCvM-0XM306291idcd6u5vig',
    class:'w-6'
  },
  {
    icon: instagramLogo,
    href: 'https://www.instagram.com/waras.delivery/',
    class:'w-6'
  },
  {
    icon: facebookLogo,
    href: 'https://www.facebook.com/WarasDelivery',
    class:'w-6'
  },
  {
    icon: whatsappLogo,
    href: 'https://wa.link/9g9kqa',
    class:'w-6'
  },
];

// companies
export const brands = [
  
  {   img: kaypacha,    href: '',  },
  {   img: casaBlanca,    href: '',  },
  {   img: rincMinero,    href: '',  },
  {   img: viejoBruno,    href: '',  },
  {   img: emiliaFerre,    href: '',  },
  {   img: kanka,    href: '',  },
  {   img: laColina,    href: '',  },
  {   img: parcero,    href: '',  },
  {   img: pashta,    href: '',  },
  {   img: bb,    href: '',  },
  {   img: pizzaAbuelo, href:''},
  {   img: zarumilla, href:''},
  {   img: rincParrillero, href:''},
  {   img: mamaMia, href:''},
  {   img: sweetPetit, href:''},
  {   img: tambo, href:''},
  {   img: tamya, href:''},
  {   img: pizzaBruno, href:''},
  {   img: taita, href:''},
  {   img: tramonti, href:''},
  {   img: wafflesHuaraz, href:''},
  {   img: pechugon, href:''},
  {   img: saboresCasa, href:''},
  {   img: kebabAndino, href:''},
  {   img: trivio, href:''},
  {   img: inkaFrut, href:''},
  {   img: rossonero, href:''},
  {   img: hnc, href:''},
  {   img: castroI, href:''},
  {   img: vincenzo, href:''},
  {   img: chifaBlas, href:''},
  {   img: hierbaBuena, href:''},
  {   img: crillon, href:''},
  {   img: rubrix, href:''},
  {   img: tresCarbones, href:''},
  {   img: jhonHouse, href:''},
  {   img: bakari, href:''},
  {   img: liStore, href:''},
  {   img: laEsquina, href:''},
  {   img: kl, href:''},
  {   img: kafer, href:''},
  {   img: sanFrancisco, href:''},
  {   img: antojo, href:''},
  {   img: barraDeMordiscos, href:''},
  {   img: mordiscos, href:''},

  
];

// projects
export const projectsData = [
  {
    id: '1',
    image: Project1,
    name: 'project name 1',
    category: 'UI/UX design',
  },
  {
    id: '2',
    image: Project2,
    name: 'project name 2',
    category: 'web development',
  },
  {
    id: '3',
    image: Project3,
    name: 'project name 3',
    category: 'UI/UX design',
  },
  {
    id: '4',
    image: Project4,
    name: 'project name 4',
    category: 'branding',
  },
  {
    id: '5',
    image: Project5,
    name: 'project name 5',
    category: 'web development',
  },
  {
    id: '6',
    image: Project6,
    name: 'project name 6',
    category: 'web development',
  },
];

// projects
export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'UI/UX Design',
  },
  {
    name: 'web development',
  },
  {
    name: 'branding',
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
];

// services
export const services = [
  {
    icon: userSVG,
    name: 'Personal',
    description:
      ' Gestionamos tus pedidos de entre nuestros más de 50 locales de confianza y la más completa variedad de comestibles, tecnología, vestimenta y accesorios. ',
  },
  {
    icon: usersSVG,
    name: 'Corporativo',
    description:
      'Afiliate. Te facilitamos los medios para que puedas visualizar los tiempos de entrega, reportes de pedidos realizados y  manejo de incidencias; de esta manera, tener una cartera de clientes consolidada.',
  },
  {
    icon: inventorySVG,
    name: 'Entregas Express',
    description:
      '¿Necesitas enviar o recoger algún paquete, encargo o producto a algun familiar o amigo? Tambien hacemos entregas rápidas. Te garantizamos la entrega de tu producto en 10 - 15 mins. desde la hora programada.',
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage1,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
  {
    authorImg: TestiImage2,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
  {
    authorImg: TestiImage3,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
];

// contact
export const contact = [
  {
    icon: mailSVG,
    title: '¿Tienes alguna duda?',
    subtitle: 'Estamos para ayudarte.',
    description: '',
  },
  {
    icon: locationSVG,
    title: 'Ubicación actual',
    subtitle: 'Huaraz - Perú',
    description: '',
  },
];
