

// import icons
import { social } from '../data';

const Socials = () => {
  return (
    <ul className='flex space-x-6 '>
      {social.map((item, index) => {
        return (
          <li
            className='flex justify-center items-center text-accent'
            key={index}
          >
            <a href={item.href}>
              <img className={item.class} src={item.icon} href={item.href}/>
            </a>
            <p className='text-white'>{item.width}</p>
            
              
            
          </li>
        );
      })}
    </ul>
  );
};

export default Socials;
