import React from 'react';

// import brands data
import { brands } from '../data';
import adjustSVG from '../assets/img/logos/icons/adjust.svg'

const Brands = () => {
  return (
    <>
    <div className='bg-tertiary flex justify-center min-h-[70px] pt-8 pb-6'>
      <h1 className='font-bold text-4xl font-body underline decoration-2 '>Nuestros socios </h1>
      <img src={adjustSVG} className='w-8 pl-4 pt-1 animate-pulse'/>
      
    </div>
   
    <section id='brands' className='min-h-[146px] bg-tertiary flex items-center'>
      
      
      
      <div
        className='container mx-auto flex md:justify-between items-center flex-wrap justify-evenly'
      >

        
        
        {brands.map((brand, idx) => {
          return (
            <div
              key={idx}
            >
              
              <a href={brand.href}>
                <img className='sm:w-44 w-28 mb-2 mt-2' src={brand.img} alt='' />
              </a>
            </div>
          );
        })}
      </div>
    </section>
    </>
  );
};

export default Brands;
